import { ref } from 'vue';
import { defineStore } from 'pinia';
import { Category, Colors, Hotel } from '@/models';
import { hotel } from '@/api';
import { LocalStorageWrapper } from '@/utils/localStorageWrapper';

export const useHotelStore = defineStore('hotel', () => {
  const currentHotel = ref({} as Hotel);
  const localStorage = new LocalStorageWrapper();
  const colors = ref<Colors | null>(localStorage.getItem('colors'));

  async function getHotel(slug: string): Promise<Hotel> {
    const { data } = await hotel.getHotel(slug);
    currentHotel.value = data;
    return data;
  }

  async function getFilePath(fileKey: string): Promise<string> {
    const { data } = await hotel.getFilePath(fileKey);
    return data;
  }

  async function getFilePathes(
    fileKeys: string[],
  ): Promise<Record<string, string>> {
    const { data } = await hotel.getFilePathes(fileKeys);
    return data;
  }

  async function getHotelCategories(slug: string): Promise<Category[]> {
    const { data } = await hotel.getHotelCategories(slug);
    return data;
  }

  async function getCategoryDetails(
    hotelSLug: string,
    slug: string,
  ): Promise<Category> {
    const { data } = await hotel.getCategoryDetails(hotelSLug, slug);
    return data;
  }

  async function searchCategoryNodes(
    query: string,
    hotelSlug: string,
  ): Promise<Category[]> {
    const { data } = await hotel.searchCategoryNodes(query, hotelSlug);
    return data;
  }

  return {
    hotel,
    colors,
    getHotel,
    getFilePath,
    getFilePathes,
    getHotelCategories,
    getCategoryDetails,
    searchCategoryNodes,
  };
});
