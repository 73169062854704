/* eslint-disable no-console */

import { register } from 'register-service-worker';
import api from '@/utils/axios';

async function subscribeUserToPush() {
  try {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      console.log('Push уведомления не поддерживаются вашим браузером.');
      return;
    }

    const permission = await Notification.requestPermission();
    if (permission !== 'granted') {
      console.log('Пользователь отклонил разрешение на отправку уведомлений.');
      return;
    }

    const registration = await navigator.serviceWorker.ready;

    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: '<ваш публичный ключ VAPID>',
    });

    await api.post('/v1/device/create', subscription);

    console.log('Пользователь успешно подписан на push уведомления.');
  } catch (error) {
    console.error('Ошибка при подписке на push уведомления:', error);
  }
}

// eslint-disable-next-line no-undef
if (process.env.NODE_ENV === 'production') {
  // eslint-disable-next-line no-undef
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
          'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      console.log('Service worker has been registered.');
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated() {
      console.log('New content is available; please refresh.');
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.',
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });

  subscribeUserToPush();
}
