<template>
  <div class="wrapper invite-page">
    <div class="atlas"></div>
    <div class="background"></div>
    <div class="gradient"></div>
    <img
      class="invite-page__logo"
      src="@/assets/images/logo-atlas.png"
      alt="Атлас"
    />
    <app-button
      class="invite-page__button"
      text="Перейти на страницу отеля"
      theme="light"
      @click="onNextPage"
    >
      <img
        alt=""
        src="@/assets/icons/SVG/arrow-circle-broken-left.svg"
        class="button__icon"
      />
    </app-button>
  </div>
</template>

<script lang="ts" setup>
  function onNextPage() {
    window.location.href = 'https://atlas.hotelky.ru';
  }
</script>

<style lang="scss" scoped>
  .invite-page {
    flex-direction: column;
    justify-content: end;

    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 50%,
      rgba(18, 62, 102, 1) 50%,
      rgba(18, 62, 102, 1) 100%
    );

    .atlas {
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 45%,
        rgba(18, 62, 102, 1) 50%,
        rgba(18, 62, 102, 1) 100%
      );
    }

    .background {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;

      height: 100vh;
      width: 100vw;
      background: url('@/assets/images/atlas.png') no-repeat top / contain;
    }

    .gradient {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 250px,
        rgba(255, 255, 255, 0) 330px,
        rgba(255, 255, 255, 0) 100%
      );
    }

    &__logo {
      z-index: 2;
      align-self: center;
      margin-bottom: 120px;
      width: 100%;
      object-fit: cover;
    }

    &__button {
      background: #123e66;
      z-index: 1000;

      margin-bottom: 35px;
    }
  }
</style>
