<template>
  <component :is="componentToRender" />
</template>

<script lang="ts" setup>
  import { computed } from 'vue';
  import { useAuthStore } from '@/stores/auth';
  import { AuthPage, OnboardingWelcomePage, HotelPage } from '@/pages';
  import { useRoute } from 'vue-router';

  const store = useAuthStore();
  const isAuth = computed(() => store.isAuth);
  const isOnboardInvisible = computed(() => store.isOnboardInvisible);
  const route = useRoute();
  const slug = route.params.slug as string;

  // const componentToRender = computed(() =>
  //   slug
  //     ? HotelPage
  //     : isAuth.value
  //       ? HistoryVisitsPage
  //       : isOnboardInvisible.value
  //         ? AuthPage
  //         : OnboardingWelcomePage,
  // );

  const componentToRender = computed(() =>
    slug ? HotelPage : OnboardingWelcomePage,
  );
</script>
