<template>
  <div class="wrapper onboard-page">
    <div class="background">
      <div class="background_onboard"></div>
      <div class="background_1 vector">
        <img src="@/assets/icons/SVG/coins.svg" alt="" />
        <span>Оплата онлайн</span>
      </div>
      <div class="background_2 vector">
        <img src="@/assets/icons/SVG/alarm-clock.svg" alt="" />
      </div>
      <div class="background_3 vector">
        <img src="@/assets/icons/SVG/waves.svg" alt="" />
        <span>SPA</span>
      </div>
      <div class="background_4 vector">
        <img src="@/assets/icons/SVG/route.svg" alt="" />
      </div>
      <div class="background_5 vector">
        <img src="@/assets/icons/SVG/towel.svg" alt="" />
        <span>Бронирование услуг</span>
      </div>
      <div class="background_6 vector">
        <img src="@/assets/icons/SVG/restaurant.svg" alt="" />
        <span>Заказ в номер из ресторана</span>
      </div>
    </div>
    <div class="onboard-page__title">Все услуги отеля у вас в кармане!</div>
    <div class="onboard-page__subtitle">
      Ваш персональный проводник<br />
      по услугам отеля
    </div>
    <app-button
      class="onboard-page__button"
      text="Далее"
      theme="active"
      @click="onNextPage"
    >
      <img
        alt=""
        src="@/assets/icons/SVG/arrow-circle-broken-left.svg"
        class="button__icon"
      />
    </app-button>
  </div>
</template>

<script lang="ts" setup>
  import router from '@/router';
  import { useAuthStore } from '@/stores/auth';
  import { useRoute } from 'vue-router';

  const store = useAuthStore();
  function onNextPage() {
    localStorage.setItem('isOnboardInvisible', 'true');
    store.changeOnboardVisible();
    // router.push({ name: 'HotelPage' });
    router.push({ name: 'HistoryPage' });
  }
</script>

<style lang="scss" scoped>
  .onboard-page {
    flex-direction: column;
    justify-content: end;
    .background {
      position: absolute;
      top: 0;
      left: 0;

      width: 100vw;
      height: calc((var(--height, 1vh) * 100) - 320px);

      &_onboard {
        position: absolute;
        z-index: 2;

        width: 100%;
        min-height: calc(var(--height, 1vh) * 100);

        background: url('@/assets/images/onboard.png') center / cover;
      }

      .vector {
        position: absolute;
        z-index: 3;

        display: flex;
        align-items: center;
        justify-content: center;

        width: min-content;
        min-width: 45px;
        height: 45px;
        padding: 12px 7px;

        color: white;
        border-radius: 50px;
        background: var(--blue-main);

        font: {
          weight: 600;
          @include toRem(size, 18px);
        }

        gap: 10px;

        img {
          @include filter-white;
          @include setSquareSize(24px);
        }
        span {
          white-space: nowrap;
        }
      }

      &_1 {
        top: 5%;
        right: -5%;

        rotate: 13deg;
      }

      &_2 {
        top: 15%;
        left: 6%;

        rotate: -11.6deg;
      }

      &_3.vector {
        top: 50%;
        right: 15%;

        padding: 12px 25px 12px 15px;

        background: white;

        rotate: -7.6deg;

        img {
          @include filter-main;
          rotate: 7.6deg;
        }
        span {
          color: var(--blue-main);
        }
      }

      &_4 {
        top: 67%;
        right: 10%;

        rotate: 10deg;
      }

      &_5 {
        top: 68%;
        left: 2%;

        rotate: 16.6deg;
      }

      &_6.vector {
        top: 90%;
        right: -1%;

        padding: 12px 13px;

        background: var(--blue-light);

        rotate: -7.3deg;
      }
    }

    &__title {
      z-index: 10;

      margin-bottom: 15px;

      font: {
        @include toRem(size, 36px);
        weight: 700;
      }

      @include toRem(line-height, 36px);
    }

    &__button {
      z-index: 10;

      background: var(--blue-main);
      border-color: var(--blue-main);

      margin-bottom: 35px;
    }

    &__subtitle {
      z-index: 10;

      margin-bottom: 70px;

      font-weight: 600;

      @include toRem(line-height, 20px);
    }
  }
</style>
