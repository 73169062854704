<template>
  <swiper-container
    ref="navbar"
    class="swiper"
    :slides-per-view="slidesPerView"
    :space-between="5"
    :free-mode="false"
  >
    <swiper-slide v-for="item in categories" :key="item.slug" class="slide">
      <a :href="`#${item.slug}`">
        <span>{{ item?.name }}</span>
      </a>
    </swiper-slide>
    <swiper-slide v-if="isHaveAccessories" key="accessories" class="slide">
      <a href="#accessories">
        <span>Доставка принадлежностей</span>
      </a>
    </swiper-slide>
  </swiper-container>
</template>

<script lang="ts" setup>
  import { onMounted, onUnmounted, ref } from 'vue';
  import { register } from 'swiper/element/bundle';
  import { Category } from '@/models';

  const props = defineProps<{
    categories: Category[];
    isHaveAccessories?: boolean;
    isOpenModal: boolean;
  }>();

  const emits = defineEmits<{
    isFixed: [value: boolean];
  }>();

  register();

  const navbar = ref<HTMLElement | null>(null);
  const inViewAnchor = ref<HTMLElement | null>(null);
  const indexViewAnchor = ref(0);
  const isTop = ref(false);
  const isAnimation = ref(false);
  const isHeaderTransparent = ref(false);
  const slidesPerView = ref('5');
  const hasAnimated = ref(true);

  function isElementInViewport(el: HTMLElement | Element) {
    const rect = el.getBoundingClientRect();
    return rect.top >= 0 && rect.top <= 140;
  }

  function isLastElementInViewport(el: HTMLElement, buffer: number) {
    const rect = el.getBoundingClientRect();
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight =
      window.innerHeight || document.documentElement.clientHeight;
    const documentHeight = document.documentElement.scrollHeight;

    const isBeyondPageBottom =
      scrollTop + windowHeight >= documentHeight - buffer;

    const isAboveViewport = rect.top < 0 && rect.bottom > 0;

    return isElementInViewport(el) || isBeyondPageBottom || isAboveViewport;
  }

  function checkVisibleSections() {
    if (!inViewAnchor.value) {
      const firstAnchor = document.querySelectorAll('a')[0];
      if (firstAnchor) changeViewAnchor(firstAnchor, 0);
    }

    const sections = document.querySelectorAll('.section');
    const lastSection = sections[sections.length - 1] as HTMLElement;
    const lastAnchor = document.querySelector(
      `a[href="#${lastSection.getAttribute('id')}"]`,
    );
    const isLastElementView = isLastElementInViewport(lastSection, 100);

    if (
      !props.isOpenModal &&
      !isAnimation.value &&
      isLastElementView &&
      inViewAnchor.value !== lastAnchor &&
      lastAnchor
    ) {
      changeViewAnchor(lastAnchor, sections.length - 1);
    } else if (!isLastElementView) {
      sections.forEach((section, index) => {
        const sectionId = section.getAttribute('id');
        const anchor = document.querySelector(`a[href="#${sectionId}"]`);

        if (
          anchor &&
          !props.isOpenModal &&
          !isAnimation.value &&
          inViewAnchor.value !== anchor
        ) {
          if (
            (isLastElementInViewport(section, 150) &&
              index === sections.length - 2) ||
            isElementInViewport(section) ||
            (isTop.value && index === 0)
          ) {
            changeViewAnchor(anchor, index);
          }
        }
      });
    }
  }

  function changeViewAnchor(currentAnchor: HTMLElement, index: number) {
    inViewAnchor.value = currentAnchor;
    const swiperEl = document.querySelector('swiper-container');
    swiperEl?.swiper.slideTo(index, 500);

    document
      .querySelectorAll('a[href^="#"]')
      .forEach(anchor => anchor.classList.remove('in-view'));
    inViewAnchor.value.classList.add('in-view');
  }

  function changeHeaderView() {
    const header = document.querySelector('.header');
    const main = document.querySelector('.main');

    if (!navbar.value || !header || !main) return;

    const mainRect = main.getBoundingClientRect();
    if (header.getBoundingClientRect().bottom <= mainRect.top) {
      adjustNavbarForSticky(header);
    } else {
      resetNavbarToDefault(header);
    }
  }

  function adjustNavbarForSticky(header: HTMLElement) {
    const navbarElement = navbar.value?.shadowRoot?.querySelector(
      '.swiper-wrapper',
    ) as HTMLElement;

    navbarElement!.style.flexWrap = 'wrap';
    slidesPerView.value = '5';
    isTop.value = true;

    Object.assign(navbar.value!.style, {
      position: 'absolute',
      top: '',
      left: '-20px',
      overflow: 'visible',
      width: 'calc(100% + 40px)',
      height: 'min-content',
      background: 'transparent',
    });

    Object.assign(header.style, {
      borderBottomRightRadius: '30px',
      borderBottomLeftRadius: '30px',
    });
    if (!hasAnimated.value) {
      triggerOpacityAnimation();
      hasAnimated.value = true;
    }
    if (isHeaderTransparent.value) header.classList.add('header_transparent');

    emits('isFixed', false);
  }

  function resetNavbarToDefault(header: HTMLElement) {
    const navbarElement = navbar.value?.shadowRoot?.querySelector(
      '.swiper-wrapper',
    ) as HTMLElement;

    navbarElement!.style.flexWrap = 'nowrap';
    if (isAnimation.value && inViewAnchor.value) {
      changeViewAnchor(inViewAnchor.value, indexViewAnchor.value);
    }
    slidesPerView.value = 'auto';
    isTop.value = false;

    Object.assign(navbar.value!.style, {
      position: 'fixed',
      top: '74px',
      left: '0',
      width: '100%',
      height: '60px',
      overflow: 'hidden',
      background: 'white',
    });

    header.style.borderBottomRightRadius = '0';
    header.style.borderBottomLeftRadius = '0';

    if (hasAnimated.value) {
      triggerOpacityAnimation();
      hasAnimated.value = false;
    }

    if (isHeaderTransparent.value)
      header.classList.remove('header_transparent');

    emits('isFixed', true);
  }

  function triggerOpacityAnimation() {
    navbar.value!.style.opacity = '0';
    setTimeout(() => {
      navbar.value!.style.transition = 'opacity 0.2s ease-in-out';
      navbar.value!.style.opacity = '1';
    }, 0);
    navbar.value!.style.transition = '';
  }

  onMounted(() => {
    const header = document.querySelector('.header');
    isHeaderTransparent.value =
      header?.classList.contains('header_transparent') || false;

    navbar.value!.style.position = 'absolute';
    navbar.value!.style.width = 'calc(100% + 40px)';

    document
      .querySelector('.swiper')
      ?.shadowRoot?.querySelector('.swiper')
      ?.setAttribute('style', 'overflow:visible; height:min-content;');
    document
      .querySelector('.swiper')
      ?.shadowRoot?.querySelector('.swiper-wrapper')
      ?.setAttribute('style', 'flex-wrap:wrap;');

    const height = (navbar.value as HTMLElement).clientHeight;
    document
      .querySelector('.element_padding-bottom')
      ?.setAttribute('style', `padding-bottom:${height + 10}px`);

    document.querySelectorAll('a[href^="#"]').forEach((anchor, index) => {
      anchor.addEventListener('click', event =>
        handleAnchorClick(event, anchor, index),
      );
    });

    window.addEventListener('scroll', changeHeaderView);
    window.addEventListener('scroll', checkVisibleSections);
    window.addEventListener('resize', checkVisibleSections);
    window.addEventListener('touchstart', () => (isAnimation.value = false));

    checkVisibleSections();
  });

  onUnmounted(() => {
    window.removeEventListener('scroll', changeHeaderView);
    window.removeEventListener('scroll', checkVisibleSections);
    window.removeEventListener('resize', checkVisibleSections);
  });

  function handleAnchorClick(event: Event, anchor: HTMLElement, index: number) {
    event.preventDefault();
    isAnimation.value = true;

    const targetElement = document.querySelector(anchor.getAttribute('href')!);
    inViewAnchor.value = anchor;
    indexViewAnchor.value = index;

    changeViewAnchor(anchor, index);
    scrollToElementTop(targetElement as HTMLElement, -130);

    checkVisibleSections();
  }

  function scrollToElementTop(elem: HTMLElement, offset: number) {
    const targetPosition =
      elem.getBoundingClientRect().top + window.pageYOffset + offset;
    window.scrollTo({ top: targetPosition, behavior: 'smooth' });
  }
</script>

<style lang="scss" scoped>
  .swiper {
    position: absolute;
    z-index: 1001;
    bottom: -5px;
    left: -20px;

    display: flex;
    overflow: visible;
    flex-wrap: wrap;

    width: calc(100% + 40px);
    height: min-content !important;
    padding: 0 20px;

    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    background: transparent;

    a {
      display: block;

      width: 100%;
      height: 100%;
      padding: 9px;

      color: var(--gray-black);

      @include none-select;
    }

    a.in-view {
      color: white;
      background: var(--primary);
    }

    .slide {
      overflow: hidden;
      flex: 1 0 auto;

      width: auto !important;
      height: min-content !important;
      margin-top: 5px;
      margin-bottom: 5px;

      text-align: center;

      border-radius: 48px;
      background: #d9d9d95c;

      font: {
        @include toRem(size, 18px);
        weight: 600;
      }

      @include toRem(line-height, 22px);
    }
  }
</style>
