<template>
  <div :id="id" :class="['service-cards', { section: title }]">
    <div v-if="title" class="service-cards__title">{{ title }}</div>
    <div
      v-for="card in cards"
      :key="card.slug"
      class="card card_small"
      @click="bookService(card)"
    >
      <img
        v-if="card?.images?.length"
        class="logo"
        :src="imageStorage + card?.images[0].fileKey"
        alt=""
      />
      <div class="title">{{ card.name }}</div>
      <div v-if="card.price" class="price">
        <div class="price__icon">₽</div>
        <div class="price__value">
          {{ parseFloat(card?.price) ? `${card.price} руб.` : 'Бесплатно' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { IService } from '@/models';
  import { imageStorage } from '@/config';

  defineProps<{
    title?: string;
    id: string;
    cards: IService[];
  }>();

  const emits = defineEmits<{
    book: [service: IService];
  }>();

  function bookService(service: IService) {
    emits('book', service);
  }
</script>

<style lang="scss" scoped>
  .service-cards {
    position: relative;

    display: flex;
    flex-wrap: wrap;

    padding: 15px 0;

    gap: 5px;

    &__title {
      width: 100%;
      margin-bottom: 15px;

      font: {
        weight: 600;
        @include toRem(size, 23px);
      }

      @include toRem(line-height, 28px);
    }
  }
  .card {
    position: relative;

    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    padding: 10px;

    border-radius: 25px;
    background: #e6e6e6;

    .image {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    &_small {
      width: calc(50% - 2.5px);
      height: 160px;
    }
  }
  .label {
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;

    width: 86%;
    height: 50px;
    padding: 6px 12px;

    color: white;
    border-top-right-radius: 25px;
    background: #00000061;

    font-weight: 600;

    backdrop-filter: blur(3px);

    &_count {
      z-index: 100;
      top: 0;
      right: 0;
      bottom: auto;
      left: auto;

      width: 70px !important;
      height: 37px;
      padding: 5px 14px;

      border-top-left-radius: 0;
      border-bottom-left-radius: 25px;
      background: #fefefe70;
    }

    &_beige {
      background: #b08d6d;
    }
    &_blue {
      background: var(--primary);
    }
  }

  .info {
    position: absolute;
    z-index: 100;
    top: 10px;
    left: 10px;

    display: flex;
    flex-direction: column;

    width: 50%;
    height: 70%;
    padding: 10px;

    border-radius: 25px;
    background: white;
  }

  .title {
    font-weight: 600;

    @include toRem(line-height, 19.49px);
  }

  .logo {
    border-radius: 50%;

    @include setSquareSize(60px);
    object-fit: cover;
  }

  .price {
    display: flex;
    align-items: center;

    font-family: 'Montserrat', 'Proxima Nova', sans-serif;

    gap: 10px;

    &__icon {
      text-align: center;

      color: white;
      border-radius: 50%;
      background: var(--primary);

      font-size: 16px;
      line-height: 23px;

      @include setSquareSize(23px);
    }

    &__value {
      color: var(--gray-black);

      @include toRem(font-size, 18px);
      @include toRem(line-height, 24px);
    }
  }

  @media screen and (min-width: 420px) {
    .card_small {
      height: 180px;
    }
  }
</style>
