<template>
  <div class="message">
    <div
      :class="[
        'message__inner',
        {
          message__inner_error: isError,
          message__inner_correct: isCorrect,
        },
      ]"
    >
      <div
        :class="[
          'message__icon',
          {
            message__icon_error: isError,
            message__icon_correct: isCorrect,
          },
        ]"
      ></div>
    </div>
    <span
      :class="[
        'message__text',
        {
          message__text_error: isError,
          message__text_correct: isCorrect,
        },
      ]"
    >
      <slot></slot>
    </span>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'AppMessage',
  };
</script>

<script lang="ts" setup>
  withDefaults(
    defineProps<{
      isError: boolean;
      isCorrect: boolean;
    }>(),
    {
      isError: false,
      isCorrect: false,
    },
  );
</script>

<style lang="scss" scoped>
  .message {
    display: flex;
    align-items: center;
    align-self: flex-start;

    font: {
      @include toRem(size, 16px);
      weight: 600;
    }

    gap: 5px;

    &__text {
      padding-top: 2px;

      &_error {
        color: var(--red-error);
      }

      &_correct {
        color: var(--primary);
      }
    }

    &__inner {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      justify-content: center;

      border-radius: 50%;

      @include setSquareSize(36px);

      &_error {
        background: var(--light-red);
      }

      &_correct {
        background: #1137ff2e;
      }
    }

    &__icon {
      width: 100%;
      height: 100%;

      &_error {
        background: url('@/assets/icons/SVG/error-icon.svg') no-repeat center;
      }

      &_correct {
        background: url('@/assets/icons/SVG/check-icon.svg') no-repeat center;
      }
    }
  }
</style>
