<template>
  <div class="wrapper onboard-welcome">
    <div class="background"></div>
    <div class="onboard-welcome__block">
      <div class="onboard-welcome__logo"></div>
      <div class="onboard-welcome__logo_big"></div>
    </div>
    <div class="onboard-welcome__title">Добро пожаловать!</div>
    <div class="onboard-welcome__subtitle">
      Ваш персональный помощник<br />
      по услугам отеля
    </div>
    <app-button
      class="onboard-welcome__button"
      text="Далее"
      theme="light"
      @click="onNextPage"
    >
      <img
        alt=""
        src="@/assets/icons/SVG/arrow-circle-broken-left.svg"
        class="button__icon"
      />
    </app-button>
  </div>
</template>

<script lang="ts" setup>
  import router from '@/router';
  import { useRoute } from 'vue-router';

  const route = useRoute();
  function onNextPage() {
    router.push({ name: 'OnboardPage', query: { hotel: route.query.hotel } });
  }
</script>

<style lang="scss" scoped>
  .onboard-welcome {
    flex-direction: column;
    justify-content: end;

    color: white;

    .background {
      position: absolute;
      top: 0;
      left: 0;

      width: 100vw;
      min-height: calc(var(--height, 1vh) * 100);

      background: url('@/assets/images/gradient-bg.png') no-repeat center /
        cover;
    }

    &__block {
      z-index: 10;

      display: flex;
      align-items: center;

      width: 100%;
      margin-bottom: 25px;
      padding: 8px;
    }

    &__logo {
      min-height: 50px;

      background: url('@/assets/images/logo-white.png') no-repeat center / cover;

      @include setSquareSize(50px);

      &_big {
        width: 100px;
        height: 20px;
        margin-left: 12px;

        background: url('@/assets/icons/SVG/logo-hotelkey.svg') no-repeat center /
          cover;

        @include filter-white;
      }
    }

    &__title {
      z-index: 10;

      margin-bottom: 15px;

      font: {
        @include toRem(size, 50px);
        weight: 700;
      }

      @include toRem(line-height, 50px);
    }

    &__subtitle {
      z-index: 10;

      margin-bottom: 70px;

      font: {
        @include toRem(size, 18px);
        weight: 600;
      }

      @include toRem(line-height, 20px);
    }

    &__button {
      z-index: 10;
      background: var(--blue-light);

      margin-bottom: 35px;
    }
  }
</style>
