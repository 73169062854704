import {
  createRouter,
  createWebHistory,
  RouteLocationNormalizedLoadedGeneric,
} from 'vue-router';
import {
  ServiceItemPage,
  ServiceConfirmPage,
  CategoryPage,
  ServicePage,
  InviteCodePage,
  CodeEmailPage,
  PhoneConfirmPage,
  HistoryOrderPage,
  OnboardingPage,
  HotelPage,
  ServicesPage,
  CartPage,
  HotelMenu,
  ProfilePage,
  AuthPage,
  CodePage,
  CodePhonePage,
  HistoryVisitsPage,
} from '@/pages';
import {
  CodePageController,
  CodePhoneController,
  WelcomePageController,
} from '@/controllers';
import InvitePage from '@/pages/InvitePage.vue';

const routes = [
  {
    path: '/',
    name: 'WelcomePage',
    component: WelcomePageController,
  },
  {
    path: '/menu',
    name: 'MenuPage',
    component: HotelMenu,
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: ProfilePage,
  },
  {
    path: '/onboard',
    name: 'OnboardPage',
    component: OnboardingPage,
  },
  {
    path: '/hotel/:slug',
    name: 'HotelPage',
    component: HotelPage,
  },
  {
    path: '/hotel/:slug/cart',
    name: 'CartPage',
    component: CartPage,
  },
  {
    path: '/hotel/:slug/:slugCategory',
    name: 'ServicesPage',
    component: ServicesPage,
  },
  {
    path: '/history/:slug',
    name: 'HistoryOrderPage',
    component: HistoryOrderPage,
  },
  {
    path: '/auth/sms',
    name: 'CodePage',
    component: CodePageController,
  },
  {
    path: '/auth/phone',
    name: 'CodePhonePage',
    component: CodePhoneController,
  },
  {
    path: '/auth/email',
    name: 'CodeEmailPage',
    component: CodeEmailPage,
  },
  {
    path: '/auth/phone/confirm',
    name: 'PhoneConfirmPage',
    component: PhoneConfirmPage,
  },
  {
    path: '/auth/invite',
    name: 'InviteCodePage',
    component: InviteCodePage,
  },
  {
    path: '/category',
    name: 'CategoryPage',
    component: CategoryPage,
  },
  {
    path: '/category/:id',
    name: 'SubCategoryPage',
    component: CategoryPage,
  },
  {
    path: '/category/:id/:service',
    name: 'ServicePage',
    component: ServicePage,
  },
  {
    path: '/category/:id/:service/service',
    name: 'ServiceItemPage',
    component: ServiceItemPage,
  },
  {
    path: '/category/:id/:service/service/confirm',
    name: 'ServiceConfirmPage',
    component: ServiceConfirmPage,
  },
];

const routesSubdomain = [
  {
    path: '/',
    name: 'HotelPage',
    component: WelcomePageController,
  },
  {
    path: '/invite',
    name: 'HistoryPage',
    component: InvitePage,
  },
  {
    path: '/auth',
    name: 'AuthPage',
    component: AuthPage,
  },
  {
    path: '/menu',
    name: 'MenuPage',
    component: HotelMenu,
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: ProfilePage,
  },
  {
    path: '/onboard',
    name: 'OnboardPage',
    component: OnboardingPage,
  },
  {
    path: '/cart',
    name: 'CartPage',
    component: CartPage,
  },
  {
    path: '/category/:slugCategory',
    name: 'ServicesPage',
    component: ServicesPage,
  },
  {
    path: '/history/:slug',
    name: 'HistoryOrderPage',
    component: HistoryOrderPage,
  },
  {
    path: '/auth/sms',
    name: 'CodePage',
    component: CodePageController,
  },
  {
    path: '/auth/phone',
    name: 'CodePhonePage',
    component: CodePhoneController,
  },
  {
    path: '/auth/email',
    name: 'CodeEmailPage',
    component: CodeEmailPage,
  },
  {
    path: '/auth/phone/confirm',
    name: 'PhoneConfirmPage',
    component: PhoneConfirmPage,
  },
  {
    path: '/auth/invite',
    name: 'InviteCodePage',
    component: InviteCodePage,
  },
];

const router = createRouter({
  routes: routesSubdomain,
  history: createWebHistory(),
});

router.beforeEach((to, _, next) => {
  const hostParts = window.location.host.split('.');
  const subdomain = hostParts[0] !== 'www' ? hostParts[0] : hostParts[1];

  if (subdomain !== 'hotelky') to.params['slug'] = 'atlas';
  next();
});

export default router;
