import { Category, Hotel } from '@/models';
import api from '@/utils/axios';
import { AxiosResponse } from 'axios';

export default class HotelService {
  static async getHotel(slug: string): Promise<AxiosResponse<Hotel>> {
    return api.get<Hotel>(`/hotel/${slug}`);
  }

  static async getFilePath(fileKey: string): Promise<AxiosResponse<string>> {
    return api.get<string>(`/storage/${fileKey}`);
  }

  static async getFilePathes(
    fileKeys: string[],
  ): Promise<AxiosResponse<Record<string, string>>> {
    return api.post<Record<string, string>>('/storage/list', {
      keys: fileKeys,
    });
  }

  static async getHotelCategories(
    slug: string,
  ): Promise<AxiosResponse<Category[]>> {
    return api.get<Category[]>(`/hotel/${slug}/service-category`);
  }

  static async getCategoryDetails(
    hotel: string,
    slug: string,
  ): Promise<AxiosResponse<Category>> {
    return api.get<Category>(
      `/service-category?hotelSlug=${hotel}&categorySlug=${slug}`,
    );
  }

  static async searchCategoryNodes(
    query: string,
    hotelSlug: string,
  ): Promise<AxiosResponse<Category[]>> {
    return api.post<Category[]>('/service-category/search', {
      query,
      hotelSlug,
    });
  }
}
