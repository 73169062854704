<template>
  <div class="wrapper cart-page">
    <div class="cart-page__header">
      <app-button
        class="cart-page__button"
        theme="gray"
        is-round
        is-square
        @click="onReturnBack"
      >
        <img class="button__icon" src="@/assets/icons/SVG/arrow.svg" alt="" />
      </app-button>
      <app-button
        v-if="isCartNotEmpty"
        class="cart-page__clear"
        text="Очистить"
        theme="gray"
        @click="clearCart"
      >
        <img src="@/assets/icons/SVG/close-icon.svg" alt="" />
      </app-button>
    </div>
    <div class="cart-page__title">Мой заказ</div>
    <cart-list
      v-if="isCartNotEmpty"
      class="cart-page__list"
      :cards="services"
      @edit="editService"
    />
    <span v-else class="cart-page__empty text-gray">Тут пока пусто...</span>
    <!-- <app-button
      v-if="isCartNotEmpty"
      class="cart-page__button_large"
      theme="gray"
      text="Оставить комментарий"
    >
      <img src="@/assets/icons/SVG/message-dots-circle.svg" alt="" />
      <img class="icon_right" src="@/assets/icons/SVG/plus.svg" alt="" />
    </app-button> -->
    <!-- <app-button
      v-if="isCartNotEmpty"
      class="cart-page__button_large"
      theme="gray"
      text="Оплатить новой картой"
    >
      <img src="@/assets/icons/SVG/credit-card.svg" alt="" />
      <img class="icon_right" src="@/assets/icons/SVG/plus.svg" alt="" />
    </app-button> -->
    <div class="bottom-block">
      <div class="cart-page__count">
        <span class="text-gray">Кол-во товаров</span>
        <span>{{ count }} шт.</span>
      </div>
      <div class="cart-page__price">
        <span>Итого</span>
        <span>{{ price ? `${price} руб.` : 'Бесплатно' }}</span>
      </div>
      <app-button
        class="cart-page__order"
        :theme="theme"
        :is-disable="!isCartNotEmpty"
        text="Забронировать"
        data-cy="buy"
        @click="createOrder"
      >
        <img alt="" src="@/assets/icons/SVG/check-white-icon.svg" />
      </app-button>
    </div>
    <transition name="modal" mode="out-in" :duration="animationDuration">
      <service-modal
        :completed-date="serviceDate"
        :is-show-modal="isModalVisible"
        :service="currentService"
        @close="setModalVisibility(false)"
      />
    </transition>
    <transition name="modal" mode="out-in" :duration="animationDuration">
      <cart-bottom-sheet
        :is-show-modal="isBottomSheetVisible"
        @close="setBottomSheetVisibility(false)"
      />
    </transition>
  </div>
</template>

<script lang="ts" setup>
  import { computed, onMounted, ref } from 'vue';
  import { Cart, IService } from '@/models';
  import { CartList, ServiceModal, CartBottomSheet } from '@/pages';
  import router from '@/router';
  import { useOrderStore } from '@/stores/cart';
  import { useAuthStore } from '@/stores/auth';
  import { animationDuration } from '@/config';

  const emits = defineEmits<{
    pop: [message: string];
  }>();

  const auth = useAuthStore();
  const isAuth = computed(() => auth.isAuth);
  const cart = useOrderStore();
  const routerLink = 'HotelPage';
  const services = ref<Cart | null>(null);
  const isModalVisible = ref(false);
  const isBottomSheetVisible = ref(false);
  const serviceDate = ref();
  const currentService = ref<IService | null>(null);

  const isCartNotEmpty = computed(() => Boolean(services.value?.items.length));
  const theme = computed(() => {
    return isCartNotEmpty.value ? 'active' : 'default';
  });
  const price = computed(() => {
    return services.value?.items.reduce((result, { service }) => {
      return result + Number(service.price);
    }, 0);
  });
  const count = computed(() => {
    return services.value?.items.reduce((result, { amount }) => {
      return result + amount;
    }, 0);
  });

  function editService(service: IService, date?: Date) {
    serviceDate.value = date;
    currentService.value = service;
    setModalVisibility(true);
  }

  async function createOrder() {
    if (isAuth.value) {
      const order = await cart.createOrder();
      if (order.paymentId) {
        const link = await cart.getOrderPaymentLink(order.paymentId);
        window.location.href = link;
      }
    } else {
      setBottomSheetVisibility(true);
    }
  }

  function setBottomSheetVisibility(value: boolean): void {
    isBottomSheetVisible.value = value;
  }

  function setModalVisibility(value: boolean): void {
    isModalVisible.value = value;
  }

  function clearCart() {
    cart.clearCart();
    services.value = cart.getCart();
  }

  function onReturnBack() {
    router.push({
      name: routerLink,
    });
  }

  onMounted(() => {
    window.scrollTo(0, 0);
    services.value = cart.getCart();
    cart.setOrderNext(false);
  });
</script>
<style lang="scss" scoped>
  .cart-page {
    flex-direction: column;
    justify-content: end;

    padding-bottom: 200px;
    &__header {
      position: fixed;
      z-index: 1002;
      top: 0;
      left: 0;

      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      min-height: 75px;
      padding: 0 20px;

      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      background: white;
    }

    &__button {
      border: none;

      .button__icon {
        filter: brightness(0) saturate(100%);
      }

      &_large {
        justify-content: start;

        margin-bottom: 5px;
        padding: 0 20px;

        color: black;
      }
    }

    &__list {
      margin-bottom: auto;
    }

    &__empty {
      margin: auto 0;

      text-align: center;
    }

    &__clear {
      width: min-content;
      padding: 0 15px;

      img {
        @include filter-gray-black;
      }
    }

    &__title {
      margin: 90px 0 30px;

      font: {
        weight: 600;
        @include toRem(size, 28px);
      }

      @include toRem(line-height, 19px);
    }

    .icon_right {
      position: absolute;
      right: 20px;

      filter: brightness(0) saturate(100%);
    }

    .bottom-block {
      position: fixed;
      z-index: 1002;
      bottom: 0;
      left: 20px;

      width: calc(100% - 40px);
      margin-top: 30px;
      padding: 15px 20px;

      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
      background: white;
      box-shadow: 0px 0px 14.3px 0px #00000021;

      font-weight: 600;
    }

    &__count {
      position: relative;

      display: flex;
      justify-content: space-between;

      width: 100%;
      padding-bottom: 50px;

      @include toRem(font-size, 18px);

      &::after {
        position: absolute;
        bottom: 23px;

        width: 100%;
        height: 1px;

        content: '';

        border-radius: 35px;
        background: var(--gray-main);
      }
    }

    &__price {
      display: flex;
      justify-content: space-between;

      width: 100%;
      padding-bottom: 30px;

      @include toRem(font-size, 20px);
    }

    .text-gray {
      color: var(--gray-black);
    }
  }
</style>
