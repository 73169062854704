<template>
  <app-modal
    v-if="isShowModal"
    class="services-modal"
    is-rounded
    @close="onCloseModal"
  >
    <div class="services-modal__block">
      <img
        src="@/assets/images/spa7.png"
        alt=""
        class="services-modal__image"
      />
      <span class="services-modal__title">
        {{ service?.translations.ru_RU.name }}
      </span>
    </div>
    <span class="services-modal__description">
      {{ service?.translations.ru_RU.description }}
    </span>
    <div v-if="hasParams" class="services-modal__params">
      <div class="services-modal__count params-block">
        <div class="params-block__title">Кол-во штук</div>
        <div
          :class="[
            'params-block__list',
            {
              'params-block__list_center': !hasSize,
            },
          ]"
        >
          <app-button
            data-cy="reduce"
            :is-disable="count === 1"
            class="params-block__button_reduce"
            theme="inactive"
            is-square
            is-round
            @click="onReduceCount"
          >
            <img alt="" src="@/assets/icons/SVG/minus.svg" />
          </app-button>
          <div class="params-block__count" data-cy="count">
            {{ count }}
          </div>
          <app-button
            data-cy="increase"
            class="params-block__button_increase"
            theme="inactive"
            is-square
            is-round
            @click="onIncreaseCount"
          >
            <img alt="" src="@/assets/icons/SVG/plus.svg" />
          </app-button>
        </div>
      </div>
      <div v-if="hasSize" class="service-modal__size params-block">
        <div class="params-block__title">Размер (EU)</div>
        <div class="params-block__list">
          <app-button
            v-for="key in sizes"
            :key="key"
            data-cy="size"
            :is-inactive="currentSize !== key"
            :is-selected="currentSize === key"
            :text="key"
            class="params-block__button"
            is-square
            is-round
            @click="onChangeSize(key)"
          >
          </app-button>
        </div>
      </div>
    </div>
    <app-button
      class="services-modal__book"
      is-disable
      text="Добавить в корзину"
      data-cy="invite"
    >
      <img alt="" src="@/assets/icons/SVG/cart.svg" />
    </app-button>
  </app-modal>
</template>

<script lang="ts" setup>
  import { ref } from 'vue';
  import { IService } from '@/models';
  import { Size } from '@/models/enums';
  import { closeModalBehavior, getEnumValues } from '@/helper';

  defineProps<{
    isShowModal: boolean;
    service: IService | null;
  }>();

  const emits = defineEmits<{
    close: [];
  }>();

  const sizes = ref<Size[]>(getEnumValues(Size));
  const hasSize = ref(true);
  const hasParams = ref(true);
  const currentSize = ref<Size>(Size.S);
  const count = ref(1);

  function onChangeSize(size: Size): void {
    currentSize.value = size;
  }

  function onIncreaseCount(): void {
    count.value++;
  }

  function onReduceCount(): void {
    count.value--;
  }

  function onCloseModal() {
    closeModalBehavior();
    emits('close');
  }
</script>

<style lang="scss" scoped>
  .services-modal {
    z-index: 1002;
    &__icon {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      justify-content: center;

      border-radius: 10px;
      background: white;

      @include setSquareSize(52px);
    }

    &__description {
      width: 100%;
      margin-bottom: 15px;
      padding: 0 8px;

      color: var(--gray-black);
    }

    &__image {
      overflow: hidden;

      border-radius: 50%;

      @include setSquareSize(60px);
      object-fit: cover;
    }

    &__block {
      display: flex;
      align-items: center;

      width: 100%;
      margin-bottom: 15px;
      padding: 0 8px;

      font: {
        weight: 600;
        @include toRem(size, 20px);
      }

      gap: 15px;
      @include toRem(line-height, 24.3px);
    }

    &__title {
      width: calc(100% - 142px);
    }

    &__book {
      z-index: 100;

      width: 100%;
    }

    &__params {
      display: flex;
      overflow: hidden;
      align-items: center;
      flex-direction: column;

      width: 100%;
      height: 70px;
      margin-bottom: 30px;

      border-radius: 30px;
      background: var(--gray-black);

      gap: 2px;
    }

    .params-block {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
      padding: 10px 25px 10px 15px;

      background: var(--light-gray);
    }
  }
</style>
