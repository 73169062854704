<template>
  <div :id="id" class="category-cards section">
    <div v-if="title" class="category-cards__title">{{ title }}</div>
    <div
      v-for="(card, index) in cards.services"
      :key="`card${index}`"
      :class="['card', cardClass(index, card)]"
      @click="bookService(card)"
    >
      <img
        v-if="card?.images?.length"
        class="image"
        :src="imageStorage + card?.images[0].fileKey"
        alt=""
      />
      <div v-if="!hasAttribute(card, 'coming_soon')" class="info">
        <div class="title">{{ card.name }}</div>
        <div class="flex">
          <div v-if="card.price" class="price">
            <div class="price__icon">₽</div>
            <div class="price__value">
              {{ parseFloat(card?.price) ? `${card.price} руб.` : 'Бесплатно' }}
            </div>
          </div>
          <div v-if="hasAttribute(card, 'informational')" class="details">
            <div class="details__text">Подробнее</div>
            <div class="details__icon">
              <img src="@/assets/icons/SVG/arrow-simple.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div v-else :class="['label']">
        {{ card?.name }}
      </div>
    </div>
    <div
      v-for="(card, index) in cards.subcategories"
      :key="`card${index}`"
      :class="['card', cardClass(index)]"
      @click="openSubcategories(card.slug)"
    >
      <img class="image" :src="imageStorage + card.logoKey" alt="" />
      <div :class="['label']">
        {{ card?.name }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { Category, IService } from '@/models';
  import { imageStorage } from '@/config';

  const props = defineProps<{
    title?: string;
    id: string;
    cards: Category;
  }>();

  const emits = defineEmits<{
    open: [slug: string];
    book: [service: IService];
  }>();

  const largeCardPositions = [3, 8, 11, 16, 19, 24];

  function hasAttribute(card: IService, attribute: string) {
    return card?.attributes?.some(val => val.type.dataType === attribute);
  }

  function isLargeCard(index: number) {
    const position = index + 1;
    if (largeCardPositions.includes(position)) {
      return true;
    }
    const isLastCard = props.cards.subcategories
      ? index === props.cards.subcategories.length - 1
      : 0;
    const isNextCardLarge =
      largeCardPositions.includes(position + 1) ||
      largeCardPositions.includes(position + 3);
    const isNextCardMissing = isLastCard && !isNextCardLarge;
    if (isNextCardMissing) {
      return true;
    }
    return false;
  }

  function cardClass(index: number, card?: IService) {
    if (card && !hasAttribute(card, 'coming_soon')) return 'card_medium';
    return {
      card_large: isLargeCard(index),
      card_small: !isLargeCard(index),
    };
  }

  function bookService(service: IService) {
    emits('book', service);
  }

  async function openSubcategories(slug: string) {
    emits('open', slug);
  }
</script>

<style lang="scss" scoped>
  .category-cards {
    position: relative;

    display: flex;
    flex-wrap: wrap;

    padding: 15px 0;

    gap: 5px;

    &__title {
      width: 100%;
      margin-bottom: 15px;

      font: {
        weight: 600;
        @include toRem(size, 23px);
      }

      @include toRem(line-height, 28px);
    }
  }
  .card {
    position: relative;

    overflow: hidden;

    width: 100%;

    border-radius: 25px;

    .image {
      width: 100%;
      height: 100%;

      object-fit: cover;
    }

    &_large {
      height: 190px;
    }

    &_medium {
      height: 264px;

      .image {
        width: 100%;
        height: 190px;

        object-fit: cover;
      }
    }

    &_small {
      width: calc(50% - 2.5px);
      height: 160px;

      .label:not(.label_count) {
        min-width: 125px;
      }
    }
  }
  .label {
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;

    width: 86%;
    height: 50px;
    padding: 6px 12px;

    color: white;
    border-top-right-radius: 25px;
    background: #00000061;

    font-weight: 600;

    backdrop-filter: blur(5px);

    &_count {
      z-index: 100;
      top: 0;
      right: 0;
      bottom: auto;
      left: auto;

      width: 70px !important;
      height: 37px;
      padding: 5px 14px;

      border-top-left-radius: 0;
      border-bottom-left-radius: 25px;
      background: #fefefe70;
    }
  }

  .info {
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    min-height: 74px;
    padding: 8px 8px 8px 16px;

    background: var(--light-gray);

    .flex {
      display: flex;
    }
  }

  .logo {
    margin-bottom: 10px;

    border-radius: 50%;

    @include setSquareSize(60px);
    object-fit: cover;
  }

  .title {
    font-weight: 600;
    padding-bottom: 5px;

    @include toRem(font-size, 18px);
    @include toRem(line-height, 19.49px);
  }

  .subtitle {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--gray-black);
    margin-bottom: 5px;

    @include toRem(font-size, 14px);
  }

  .price {
    display: flex;
    align-items: center;

    font-family: 'Montserrat', 'Proxima Nova', sans-serif;

    gap: 10px;

    &__icon {
      text-align: center;

      color: white;
      border-radius: 50%;
      background: var(--primary);

      font-size: 16px;
      line-height: 23px;

      @include setSquareSize(23px);
    }

    &__value {
      @include toRem(line-height, 24px);
    }
  }

  .description {
    margin-bottom: 10px;
  }

  .details {
    display: flex;
    justify-content: end;
    align-items: center;

    margin-left: auto;

    font-family: 'Proxima Nova', sans-serif;

    gap: 8px;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      padding-left: 3px;

      color: white;
      border-radius: 50%;
      background: white;

      font-size: 16px;
      line-height: 23px;

      @include setSquareSize(30px);
    }

    &__text {
      font-weight: 600;
    }
  }

  @media screen and (min-width: 420px) {
    .card_small {
      height: 180px;
    }
    .label {
      height: 60px;

      &_count {
        width: 80px !important;
        height: 50px;
      }
    }

    .card_small {
      .label:not(.label_count) {
        min-width: 170px !important;
      }
    }
  }

  @media screen and (min-width: 360px) {
    .card_small {
      .label:not(.label_count) {
        min-width: 145px;
      }
    }
  }
</style>
