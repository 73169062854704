<template>
  <component :is="componentToRender" />
</template>

<script lang="ts" setup>
  import { computed, onMounted, ref } from 'vue';
  import { useAuthStore } from '@/stores/auth';
  import { CodePhonePage } from '@/pages';
  import router from '@/router';

  const store = useAuthStore();
  const isAuth = computed(() => store.isAuth);

  const componentToRender = ref(CodePhonePage);

  onMounted(() => {
    if (isAuth.value) router.push({ name: 'HotelPage' });
  });
</script>
