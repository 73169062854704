import { Visit } from '@/models';
import { OrderStatus } from '@/models/enums';

const room = '01cbc698-ffd4-11ee-a7da-7bc76bdee00e';
const slugHotel = 'imperial-spb';
const animationDuration = 550;
const imageStorage = 'https://hotelkey-prod.storage.yandexcloud.net/';

const visits: Visit[] = [
  {
    name: 'Palace',
    slug: 'palace',
    checkIn: new Date(2024, 2, 21),
    checkOut: new Date(2024, 2, 24),
    image: 'palace-image',
    icon: 'palace',
    orders: [
      {
        id: '185d4b00-8253-11ef-b0f7-1f9f3adc2c97',
        status: OrderStatus.COMPLETED,
        comment: null,
        client: {
          id: '185d4b00-8253-11ef-b0f7-1f9f3adc2c97',
          updatedAt: new Date(2024, 2, 22),
          createdAt: new Date(2024, 2, 22),
          removedAt: new Date(2024, 2, 22),
        },
        items: [
          {
            id: '185d4b01-8253-11ef-b0f7-1f9f3adc2c97',
            amount: 1,
            meta: {
              slot: new Date(2024, 2, 22),
            },
            service: {
              id: 'e9742c46-703c-11ef-99ae-3eb01d155c5c',
              slug: 'riviera-kazan_spa-swedish-massage',
              translations: {
                ru_RU: {
                  name: 'Шведский массаж',
                  description:
                    'Шведский массаж для глубокого расслабления и оздоровления',
                },
              },
              price: 3500,
              imageKey: 'swedish_massage_image',
              updatedAt: new Date(2024, 2, 22),
              createdAt: new Date(2024, 2, 22),
              removedAt: null,
            },
          },
          {
            id: '185d4b02-8253-11ef-b0f7-1f9f3adc2c97',
            amount: 1,
            meta: {
              slot: new Date(2024, 2, 22),
            },
            service: {
              id: 'e9742c46-703c-11ef-99ae-3eb01d155c5c',
              slug: 'riviera-kazan_spa-swedish-massage',
              translations: {
                ru_RU: {
                  name: 'Шведский массаж',
                  description:
                    'Шведский массаж для глубокого расслабления и оздоровления',
                },
              },
              price: 3500,
              imageKey: 'swedish_massage_image',
              updatedAt: new Date(2024, 2, 22),
              createdAt: new Date(2024, 2, 22),
              removedAt: null,
            },
          },
          {
            id: '185d4b03-8253-11ef-b0f7-1f9f3adc2c97',
            amount: 1,
            meta: {
              slot: new Date(2024, 2, 24),
            },
            service: {
              id: 'e97444c6-703c-11ef-99b0-3eb01d155c5c',
              slug: 'riviera-kazan_spa-aromatherapy-session',
              translations: {
                ru_RU: {
                  name: 'Сеанс ароматерапии',
                  description: 'Расслабляющие процедуры с эфирными маслами',
                },
              },
              price: 3000,
              imageKey: 'aromatherapy_image',
              updatedAt: new Date(2024, 2, 24),
              createdAt: new Date(2024, 2, 24),
              removedAt: null,
            },
          },
          {
            id: '185d4b04-8253-11ef-b0f7-1f9f3adc2c97',
            amount: 1,
            meta: {
              slot: new Date(2024, 2, 26),
            },
            service: {
              id: 'e97444c6-703c-11ef-99b0-3eb01d155c5c',
              slug: 'riviera-kazan_spa-aromatherapy-session',
              translations: {
                ru_RU: {
                  name: 'Сеанс ароматерапии',
                  description: 'Расслабляющие процедуры с эфирными маслами',
                },
              },
              price: 3000,
              imageKey: 'aromatherapy_image',
              updatedAt: new Date(2024, 2, 26),
              createdAt: new Date(2024, 2, 26),
              removedAt: null,
            },
          },
          {
            id: '185d4b05-8253-11ef-b0f7-1f9f3adc2c97',
            amount: 1,
            meta: {
              slot: new Date(2024, 2, 23),
            },
            service: {
              id: 'e97436a0-703c-11ef-99af-3eb01d155c5c',
              slug: 'riviera-kazan_spa-sauna-rental',
              translations: {
                ru_RU: {
                  name: 'Аренда сауны',
                  description: 'Аренда сауны для частного использования',
                },
              },
              price: 2500,
              imageKey: 'sauna_rental_image',
              updatedAt: new Date(2024, 2, 23),
              createdAt: new Date(2024, 2, 23),
              removedAt: null,
            },
          },
        ],
        updatedAt: new Date(2024, 2, 22),
        createdAt: new Date(2024, 2, 22),
        removedAt: new Date(2024, 2, 22),
      },
      {
        id: '185d4b00-8253-11ef-b0f7-1f9f3adc2c97',
        status: OrderStatus.COMPLETED,
        comment: null,
        client: {
          id: '185d4b00-8253-11ef-b0f7-1f9f3adc2c97',
          updatedAt: new Date(2024, 2, 22),
          createdAt: new Date(2024, 2, 22),
          removedAt: new Date(2024, 2, 22),
        },
        items: [
          {
            id: '185d4b01-8253-11ef-b0f7-1f9f3adc2c97',
            amount: 1,
            meta: {
              slot: new Date(2024, 2, 22),
            },
            service: {
              id: 'e9742c46-703c-11ef-99ae-3eb01d155c5c',
              slug: 'riviera-kazan_spa-swedish-massage',
              translations: {
                ru_RU: {
                  name: 'Шведский массаж',
                  description:
                    'Шведский массаж для глубокого расслабления и оздоровления',
                },
              },
              price: 3500,
              imageKey: 'swedish_massage_image',
              updatedAt: new Date(2024, 2, 22),
              createdAt: new Date(2024, 2, 22),
              removedAt: null,
            },
          },
          {
            id: '185d4b02-8253-11ef-b0f7-1f9f3adc2c97',
            amount: 1,
            meta: {
              slot: new Date(2024, 2, 22),
            },
            service: {
              id: 'e9742c46-703c-11ef-99ae-3eb01d155c5c',
              slug: 'riviera-kazan_spa-swedish-massage',
              translations: {
                ru_RU: {
                  name: 'Шведский массаж',
                  description:
                    'Шведский массаж для глубокого расслабления и оздоровления',
                },
              },
              price: 3500,
              imageKey: 'swedish_massage_image',
              updatedAt: new Date(2024, 2, 22),
              createdAt: new Date(2024, 2, 22),
              removedAt: null,
            },
          },
          {
            id: '185d4b03-8253-11ef-b0f7-1f9f3adc2c97',
            amount: 1,
            meta: {
              slot: new Date(2024, 2, 24),
            },
            service: {
              id: 'e97444c6-703c-11ef-99b0-3eb01d155c5c',
              slug: 'riviera-kazan_spa-aromatherapy-session',
              translations: {
                ru_RU: {
                  name: 'Сеанс ароматерапии',
                  description: 'Расслабляющие процедуры с эфирными маслами',
                },
              },
              price: 3000,
              imageKey: 'aromatherapy_image',
              updatedAt: new Date(2024, 2, 24),
              createdAt: new Date(2024, 2, 24),
              removedAt: null,
            },
          },
          {
            id: '185d4b04-8253-11ef-b0f7-1f9f3adc2c97',
            amount: 1,
            meta: {
              slot: new Date(2024, 2, 26),
            },
            service: {
              id: 'e97444c6-703c-11ef-99b0-3eb01d155c5c',
              slug: 'riviera-kazan_spa-aromatherapy-session',
              translations: {
                ru_RU: {
                  name: 'Сеанс ароматерапии',
                  description: 'Расслабляющие процедуры с эфирными маслами',
                },
              },
              price: 3000,
              imageKey: 'aromatherapy_image',
              updatedAt: new Date(2024, 2, 26),
              createdAt: new Date(2024, 2, 26),
              removedAt: null,
            },
          },
          {
            id: '185d4b05-8253-11ef-b0f7-1f9f3adc2c97',
            amount: 1,
            meta: {
              slot: new Date(2024, 2, 23),
            },
            service: {
              id: 'e97436a0-703c-11ef-99af-3eb01d155c5c',
              slug: 'riviera-kazan_spa-sauna-rental',
              translations: {
                ru_RU: {
                  name: 'Аренда сауны',
                  description: 'Аренда сауны для частного использования',
                },
              },
              price: 0,
              imageKey: 'sauna_rental_image',
              updatedAt: new Date(2024, 2, 23),
              createdAt: new Date(2024, 2, 23),
              removedAt: null,
            },
          },
        ],
        updatedAt: new Date(2024, 2, 22),
        createdAt: new Date(2024, 2, 21),
        removedAt: new Date(2024, 2, 22),
      },
    ],
  },
  {
    name: 'Мрия',
    slug: 'mriya2',
    checkIn: new Date(2024, 2, 21),
    checkOut: new Date(2024, 2, 24),
    image: 'mriya-image',
    icon: 'mriya',
    orders: [],
  },
  {
    name: 'Мрия',
    slug: 'mriya',
    checkIn: new Date(2023, 2, 21),
    checkOut: new Date(2023, 2, 24),
    image: 'mriya-image',
    icon: 'mriya',
    orders: [],
  },
  {
    name: 'Ялта Интурист',
    slug: 'inturist',
    checkIn: new Date(2023, 2, 21),
    checkOut: new Date(2023, 2, 24),
    image: 'inturist-image',
    icon: 'inturist',
    orders: [],
  },
];

const menu = [
  {
    name: 'Главная',
    icon: 'home',
    link: 'HotelPage',
    isActive: true,
  },
  {
    name: 'Мой профиль',
    icon: 'user',
    link: 'ProfilePage',
    isActive: true,
  },
  {
    name: 'Уведомления',
    icon: 'notifications',
    link: 'NotificationPage',
    isActive: false,
  },
  {
    name: 'Мои заказы',
    icon: 'list',
    link: 'OrdersPage',
    isActive: false,
  },
  {
    name: 'Контакты',
    icon: 'phone-incoming',
    link: 'ContactsPage',
    isActive: false,
  },
];

export { room, slugHotel, menu, animationDuration, visits, imageStorage };
