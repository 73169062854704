<template>
  <div class="app">
    <div class="container">
      <!-- Пока скрываем боттом щит с заказом -->
      <!-- <transition name="modal" mode="out-in" :duration="animationDuration">
        <order-bottom-sheet v-if="orders.length && isAuth" :order="lastOrder" />
      </transition> -->
      <transition name="popup" mode="out-in" :duration="animationDuration">
        <popup-notification
          v-show="isShowPopup"
          :is-error="isOffline"
          :is-correct="!isOffline"
        >
          {{ popupMessage }}
        </popup-notification>
      </transition>
      <router-view v-slot="{ Component, route }">
        <transition name="page" mode="out-in">
          <component
            :is="Component"
            :key="route.name"
            :deferred-prompt="deferredPrompt"
            @pop="changePopupMessage"
            @clear-prompt="clearDeferredPrompt"
          />
        </transition>
      </router-view>
    </div>
  </div>
</template>

<script lang="ts">
  export default {
    name: 'App',
  };
</script>

<script lang="ts" setup>
  import {
    computed,
    onBeforeMount,
    onMounted,
    onUnmounted,
    ref,
    watch,
  } from 'vue';
  import { useAuthStore } from '@/stores/auth';
  import { animationDuration } from '@/config';
  import { OrderBottomSheet } from '@/pages';
  import { Order } from './models';
  import { useOrderStore } from '@/stores/cart';
  import { useHotelStore } from './stores/hotel';

  const store = useAuthStore();
  const order = useOrderStore();
  const hotel = useHotelStore();
  const isAuth = computed(() => store.isAuth);
  const colors = computed(() => hotel.colors);
  const lastOrder = computed(() => orders.value.slice(-1)[0]);
  const orders = ref<Order[]>([]);
  const height = ref(0);
  const isShowPopup = ref(false);
  const isOffline = ref(false);
  const popupMessage = ref('');
  const timer = ref();
  const deferredPrompt = ref<Event | null>(null);

  function setWindowHeight(event?: Event) {
    let vh: number;
    if (!event) vh = window.innerHeight * 0.01;
    else {
      vh = event.target?.height * 0.01;
    }
    return vh;
  }

  async function changePopupMessage(message: string) {
    if (isAuth.value) orders.value = await order.getOrders();
    popupMessage.value = message;
    showPopup();
  }

  function showPopup() {
    clearTimeout(timer.value);
    isShowPopup.value = false;
    timer.value = setTimeout(() => {
      isShowPopup.value = true;
    }, animationDuration);
    timer.value = setTimeout(() => {
      isShowPopup.value = false;
    }, 3000);
  }

  function clearDeferredPrompt() {
    deferredPrompt.value = null;
  }

  function changeColorTheme() {
    if (colors.value) {
      document.documentElement.style.setProperty(
        '--primary',
        colors.value.primaryColor,
      );
      document.documentElement.style.setProperty(
        '--secondary',
        colors.value.secondaryColor,
      );
      document.documentElement.style.setProperty(
        '--primary-accent',
        colors.value.primaryAccentColor,
      );
      document.documentElement.style.setProperty(
        '--secondary-accent',
        colors.value.secondaryAccentColor,
      );
    }
  }

  watch(
    () => isAuth.value,
    async () => {
      if (isAuth.value) orders.value = await order.getOrders();
    },
  );

  watch(() => colors.value, changeColorTheme);

  interface BeforeInstallPromptEvent extends Event {
    prompt: () => Promise<void>;
    userChoice: Promise<{ outcome: 'accepted' | 'dismissed' }>;
  }

  onMounted(async () => {
    if (!navigator.onLine) {
      isOffline.value = true;
      changePopupMessage('Соединение потеряно...');
    }
    window.addEventListener('offline', () => {
      isOffline.value = true;
      changePopupMessage('Соединение потеряно...');
    });
    window.addEventListener('online', () => {
      isOffline.value = false;
      changePopupMessage('Соединение восстановлено!');
    });
    height.value = setWindowHeight();
    document.documentElement.style.setProperty('--height', `${height.value}px`);
    await store.checkAuth();
    if (isAuth.value) orders.value = await order.getOrders();

    function handleBeforeInstallPrompt(
      event: BeforeInstallPromptEvent | Event,
    ) {
      event.preventDefault();
      deferredPrompt.value = event as BeforeInstallPromptEvent;
    }

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  });

  onBeforeMount(() => {
    changeColorTheme();
  });

  onUnmounted(() => {
    visualViewport?.removeEventListener('resize', setWindowHeight);
  });
</script>

<style lang="scss">
  .app {
    position: relative;

    display: block;
    overflow-x: hidden;
    overflow-y: hidden;

    width: 100vw;
    height: auto;
    min-height: calc(var(--height, 1vh) * 100);

    background: white;

    font: {
      family: 'Proxima Nova', sans-serif;
      weight: 400;
      size: 1rem;
    }
    line-height: 0.9375rem;

    .page-enter-active,
    .page-leave-active {
      transition: opacity 0.15s ease;
    }

    .page-enter-from,
    .page-leave-to {
      opacity: 0;
    }
  }
</style>
