<template>
  <div class="wrapper hotel-page">
    <app-header :logo="logo" @book="openBookModal" />
    <hotel-skeleton v-if="isLoading" />
    <div v-else class="hotel-page__block">
      <div class="hotel-page__header element_padding-bottom">
        <!-- <span class="hotel-page__subtitle">Валерия, добро пожаловать!</span> -->
        <!-- <span class="hotel-page__title">Отель “{{ hotel?.name }}”</span> -->
        <!-- <div class="hotel-page__subtitle hotel-page__text_gray">
          {{ hotel?.address }}
        </div> -->
        <img
          class="hotel-page__logo"
          :src="imageStorage + hotel.logoKey"
          :alt="hotel.name"
        />
        <hotel-navbar
          v-if="categories.length"
          :is-open-modal="isAnimation"
          :categories="categories"
          @is-fixed="val => (isFixedNavbar = val)"
        />
      </div>
      <div class="hotel-page__main main">
        <hotel-list
          v-for="(category, index) in categories"
          :id="category.slug"
          :key="category.slug"
          :title="index === 0 ? '' : category?.name"
          :cards="category"
          @open="openCategoryDetails"
          @book="openBookModal"
        />
      </div>
    </div>
    <div class="background">
      <div class="background__gradient_1"></div>
      <div class="background__gradient_2"></div>
      <div class="background__gradient_3"></div>
    </div>
    <service-modal
      :is-show-modal="isModalVisible"
      :service="currentService"
      @close="changeModal(false)"
    />
    <transition name="modal" mode="out-in" :duration="animationDuration">
      <accessories-modal
        :is-show-modal="isModalAccessoriesVisible"
        :service="currentService"
        @close="changeAccessoriesModal(false)"
      />
    </transition>
  </div>
</template>

<script lang="ts" setup>
  import { onBeforeMount, onMounted, ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import AppHeader from '@/components/AppHeader.vue';
  import { LocalStorageWrapper } from '@/utils/localStorageWrapper';
  import {
    HotelNavbar,
    HotelList,
    HotelSkeleton,
    ServiceModal,
    AccessoriesModal,
  } from '@/pages';
  import { useHotelStore } from '@/stores/hotel';
  import { Category, Hotel, IService } from '@/models';
  import router from '@/router';
  import { animationDuration } from '@/config';
  import { imageStorage } from '@/config';

  const store = useHotelStore();
  const localStorage = new LocalStorageWrapper();
  const hotel = ref<Hotel>({} as Hotel);
  const categories = ref<Category[]>([]);
  const currentService = ref<IService | null>(null);
  const route = useRoute();
  const isLoading = ref(true);
  const isFixedNavbar = ref(false);
  const isModalVisible = ref(false);
  const isModalAccessoriesVisible = ref(false);
  const isAnimation = ref(false);
  const logo = ref('');

  async function openCategoryDetails(slug: string) {
    router.push({
      name: 'ServicesPage',
      params: { slugCategory: slug },
    });
  }

  function changeModal(value: boolean): void {
    isAnimation.value = value;
    isModalVisible.value = value;
  }

  function changeAccessoriesModal(value: boolean): void {
    isAnimation.value = value;
    isModalAccessoriesVisible.value = value;
  }

  function openBookModal(service: IService): void {
    currentService.value = service;
    changeModal(true);
  }

  function changeColorTheme() {
    if (hotel.value?.theme) {
      document.documentElement.style.setProperty(
        '--primary',
        hotel.value?.theme.primaryColor,
      );
      document.documentElement.style.setProperty(
        '--secondary',
        hotel.value?.theme.secondaryColor,
      );
      document.documentElement.style.setProperty(
        '--primary-accent',
        hotel.value?.theme.primaryAccentColor,
      );
      document.documentElement.style.setProperty(
        '--secondary-accent',
        hotel.value?.theme.secondaryAccentColor,
      );
    }
  }

  watch(
    () => isFixedNavbar.value,
    () => {
      if (isFixedNavbar.value) logo.value = imageStorage + hotel.value.logoKey;
      else logo.value = '';
    },
  );

  onMounted(async () => {
    window.scrollTo(0, 0);
    categories.value = await store
      .getHotelCategories(route.params.slug as string)
      .then(data => {
        isLoading.value = false;
        return data;
      });
  });

  onBeforeMount(async () => {
    hotel.value = await store.getHotel(route.params.slug as string);
    localStorage.setItem('colors', hotel.value?.theme);
    changeColorTheme();
  });
</script>

<style lang="scss" scoped>
  .hotel-page {
    flex-direction: column;

    margin-top: 87px;
    margin-bottom: 92px;

    &__block {
      z-index: 100;

      display: flex;
      flex-direction: column;
    }

    &__logo {
      align-self: center;
      margin-bottom: 10px;
      width: 100%;
      object-fit: cover;
    }

    &__header {
      position: relative;

      display: flex;
      flex-direction: column;

      padding-bottom: 124px;
    }

    &__subtitle {
      font: {
        weight: 600;
        @include toRem(size, 18px);
      }

      @include toRem(line-height, 21.92px);
    }
    &__title {
      font: {
        weight: 600;
        @include toRem(size, 38px);
      }

      @include toRem(line-height, 46.28px);
    }

    &__text_gray {
      margin-top: 5px;

      color: var(--gray-black);
    }

    .background {
      &__gradient_1 {
        position: absolute;
        top: 90px;
        right: -50px;

        background: radial-gradient(
          circle,
          rgba(17, 55, 255, 0.15) 15%,
          rgba(0, 0, 0, 0) 75%
        );

        @include setSquareSize(180px);
      }

      &__gradient_2 {
        position: absolute;
        top: 150px;
        right: 70px;

        background: radial-gradient(
          circle,
          rgba(243, 128, 47, 0.15) 10%,
          rgba(0, 0, 0, 0) 75%
        );

        @include setSquareSize(110px);
      }

      &__gradient_3 {
        position: absolute;
        top: 930px;
        left: -80px;

        background: radial-gradient(
          circle,
          rgba(243, 128, 47, 0.2) 15%,
          rgba(0, 0, 0, 0) 75%
        );

        @include setSquareSize(260px);
      }
    }
  }
</style>
